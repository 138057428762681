<template>
  <div class="orderList-view view pa24">
    <el-row :gutter="24">

    </el-row>
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="pageNum"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column prop="createTime" width="100" align="center" label="创建时间">
        </el-table-column>
         <el-table-column prop="id" align="center" label="页面id">
        </el-table-column>
        <el-table-column prop="title" align="center" label="页面标题">
        </el-table-column>
        <el-table-column prop="departments" align="center" label="绑定部门">
          <template slot-scope="scope">
            <span v-for="item in scope.row.departments" :key="item.id">{{item.name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
              <el-button type="text" size="mini" :disabled="!hasPermi" @click="delCreatePage(scope.row)">删除</el-button>
              <el-button type="text" size="mini" :disabled="!hasPermi" @click="bindDepartment(scope.row)">绑定部门</el-button>
              <el-button type="text" size="mini" @click="lookPage(scope.row)">查看</el-button>
              <el-button type="text" size="mini" @click="editPage(scope.row)" :disabled="!hasPermi">编辑页面</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <!--生成标识弹窗-->
    <el-dialog
      title="生成标识"
      width="600px"
      ref="form"
      :modal-append-to-body="false"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item prop="type" label="类型">
         <el-select v-model="ruleForm.type" placeholder="请选择类型" @change="getUsedCount">
            <el-option
              v-for="item in TypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <span style="padding-left: 20px;">可使用数量: {{typeUsedCount}}</span>
        </el-form-item>
        <el-form-item prop="number" label="数量">
          <el-input v-model="ruleForm.number" placeholder="请输入数量"></el-input>
        </el-form-item>
        <el-form-item prop="scanNum" label="失效次数">
          <el-input v-model="ruleForm.scanNum" placeholder="请输入失效次数"></el-input>
        </el-form-item>
        
        <el-form-item prop="remark" label="备注">
          <el-input v-model="ruleForm.remark" type="textarea" rows="2" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button class="btncss" @click="confirmCreateHandle()">确 定</el-button>
      </div>
    </el-dialog>

    <!--查看弹窗----->
    <SeeCustomDialog ref="seeCustomDialog"></SeeCustomDialog>
    <!--查看部门弹窗-->
    <DepartmentDialog ref="departmentDialog" @getcheckDepArr="getcheckDepArr" :checkDepArr="checkDepArr"></DepartmentDialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import SeeCustomDialog from '@/components/seeCustomDialog.vue'
import DepartmentDialog from './departmentDialog'
import { fetchCreatePageList, fetchCreateHandle, fetchGetCustomCountByType, fetchDeleteCreatePage, fetchDepartment, fetchCheckDepartment } from '@/api/customPage'
export default {
  name: "customlist",
  components: {
    commonTable,
    SeeCustomDialog,
    DepartmentDialog
  },
  data() {
    return {
      hasPermi: true,
      pickerValue: "", //下单时间
      loading:false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      start:'',
      end:'',
      tableData: [],
      pageNum: 1,
      pageSize: 10,
     
      // 弹窗
      dialogFormVisible: false,
      ruleForm: {
        number: "",
        remark : "",
        scanNum: '', // -1为无限
        type: "", // 类型
      },
      TypeOptions: [
        {
          value: 1,
          label: '宣传物料（海报、彩页、手册、手提袋、纸巾包、钥匙扣等）'
        },
        {
          value: 2,
          label: '产品'
        },
        {
          value: 3,
          label: '箱标'
        },
        {
          value: 4,
          label: '证书'
        },
        {
          value: 6,
          label: '套标'
        },
      ],
      typeUsedCount: 0,
      rules: {
        scanNum: [{ required: true, message: "请输入失效次数", trigger: "blur" }],
        number: [{ required: true, message: "请输入数量", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "change" }]
      },
      curId: null,
      // 一码多权
      checkDepArr: [],
      curDept: ''
    };
  },
  props:{
    serchParams:{
      type :String,
      default:''
    },
  },
  created() {
    this.selectOrdersInfoPcAsync();
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
  watch:{
  },
  filters: {
  },
  methods: {
    
    lookPage(data) {
      this.$refs.seeCustomDialog.visible = true
      this.$refs.seeCustomDialog.init(data.id)
    },
    editPage(data) {
      this.$router.push({ path: '/mkc/customPage', query: { pageId: data.id , type:'editPage', templateType: 1 }})
    },
    createHandle(data) {
      this.ruleForm = {
        number: "",
        scanNum: "",
        remark : "",
        type: undefined,
      }
      this.curId = data.id
      this.dialogFormVisible = true
    },
    // 删除页面
    delCreatePage(data) {
      this.$confirm("确定删除此页面吗?", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info ",
      })
      .then(() => {
        let params = {
          id: data.id,
          isDel: true,
        }
        fetchDeleteCreatePage(params)
        .then(res=>{
          if(res.code == 200){
            this.$message({
              type: "success",
              message: "操作成功！",
            });
          }
          this.selectOrdersInfoPcAsync();
        })
      })
    },
    closeDialog() {
      this.ruleForm = {
        number: "",
        scanNum: "",
        remark : "",
        type: undefined,
      }
      this.typeUsedCount = 0
      this.$refs.ruleForm.resetFields();
      this.dialogFormVisible = false
    },
    // 跳转标识页面列表
    goHandlePageList(lotNumber, customPageId) {
      this.$router.push({ path: '/mkc/handlePageList', query: { lotNumber: lotNumber , customPageId: customPageId }})
    },
    // 生成标识
    confirmCreateHandle() {
      console.log(this.ruleForm)
      console.log(this.curId)
      let v= this
      this.$refs.ruleForm.validate(async (valid) => {
          if(Number(v.ruleForm.number) > this.typeUsedCount) {
            this.$message({
              message: '超出可使用数量',
              type: 'warning'
            });
            return
          }
          if (valid) {
            try {
              let params = {
                pageId: v.curId,
                number: Number(v.ruleForm.number),
                remark: v.ruleForm.remark,
                type: v.ruleForm.type,
                scanNum: v.ruleForm.scanNum
              }
              let res= await fetchCreateHandle(params);
              this.dialogFormVisible = false;
              if(res.code === 200) {
                console.log('生成成功',res)
              //  this.$router.push('/createPageList')
                this.$message({
                  type: "success" ,
                  message: "操作成功！",
                });

                this.selectOrdersInfoPcAsync()
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
    },
    /**@method 获取页面列表 */
    async selectOrdersInfoPcAsync() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // handle: this.serchParams,
        type: 1,
      }
      const result = await fetchCreatePageList(params)
      this.tableData = result.data.list
      this.total = result.data.total
      console.log(result)
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.selectOrdersInfoPcAsync();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.selectOrdersInfoPcAsync();
    },
    getUsedCount(data) {
      let params= {
        companyId: JSON.parse(localStorage.getItem('info')).companyId,
        type: data
      }
      fetchGetCustomCountByType(params).then(res => {
        this.typeUsedCount=res.data.count - res.data.usedCount
      })
    },
    // 绑定部门
    bindDepartment(row) {
      this.curDept=row.id
      this.checkDepArr = row.departments && row.departments.length? row.departments.map(item => parseInt(item.id)): []
      this.$refs.departmentDialog.visible = true
    },
    getcheckDepArr(data) {
      this.checkDepArr=data
      let params = {
        departmentIds: this.checkDepArr,
        pageId: this.curDept,
        type: 0, // 0绑定部门 1绑定页面
      }
      fetchCheckDepartment(params).then( res => {
        this.selectOrdersInfoPcAsync();
      })
      console.log('this.checkDepArr', this.checkDepArr)
      
    }
    
  },
};
</script>

<style scoped lang='scss'>
.lotSpan{
  // border: 1px solid #ccc;
  padding: 0 10px;
  cursor: pointer;
  width: auto;
  line-height: 30px;
  text-decoration: underline;
}
</style>
