<template>
  <div>
    <el-dialog
      title=""
      width="600px"
      :modal-append-to-body="false"
      :visible.sync="visible"
      :close-on-click-modal="false"
    >
      <el-checkbox-group v-model="checkList">
        <el-checkbox v-for="item in tableData" :key="item.departmentId" :label="item.departmentId">{{item.name}}</el-checkbox>
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogClose">取 消</el-button>
        <el-button class="btncss" @click="confirmDepartment()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { fetchDepartment } from '@/api/customPage'
export default {
  components:{
  },
  props: ['checkDepArr'],
  data(){
    return {
      visible: false,
      tableData: [],
      checkList: [],
    }
  },
  computed: {

  },
  created(){
    this.init()
  },
  methods:{
    init() {
      let params = {
        pageNum: 1,
        pageSize: 1000,
      }
      fetchDepartment(params).then(res => {
        this.tableData = res.data.list
      })
    },
    confirmDepartment() {
      // console.log(this.checkList)
      this.$emit('getcheckDepArr', this.checkList)
      this.visible = false
    },
    dialogClose() {
      this.visible = false
      this.checkList = []
    }
  },
  watch: {
    visible (val) {
      if(this.visible) {
        this.checkList = this.checkDepArr
      }
    }
  },
}
</script>
<style scoped>
.container .mobileContainer {
  height: 550px!important;
}
</style>